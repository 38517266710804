import ThemeOptions from "./ThemeOptions";
import pickingListApiReducer from "./pickingListApiReducer"
import orderSlice from "./orderSlice";
import orderShowSlice from "./orderShowSlice";
import warehouseStockBySkuSlice from "./warehouseStockBySkuSlice";
import simplePickingSlice from "./simplePickingSlice";
import simplePackingSlice from "./simplePackingSlice";
import pickingInboxSlice from "./pickingInboxSlice";
import revisionListSlice from "./revisionListSlice";

export default {
  ThemeOptions,
  orders: orderSlice,
  orderShow: orderShowSlice,
  simplePickingList: simplePickingSlice,
  simplePackingList: simplePackingSlice,
  pickingListInbox: pickingInboxSlice,
  revisionList: revisionListSlice,
  warehouseStockBySku: warehouseStockBySkuSlice,
  pickingList: pickingListApiReducer
};
