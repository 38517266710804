import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    loading: false,
    data: {
        total: 0,
        data: [],
        simpleDashboard: {
            pending: 0,
            picking: 0,
            packing: 0,
            delivery: 0
        }
    },
    params: {
        page: 1,
        sizePerPage: 10,
        filters: [],
        sortField: undefined,
        sortOrder: undefined
    },
    error: ''
};

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async ({params, accessToken}) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/orders', {
            headers: {
                Authorization: "Bearer " + accessToken
            },
            params
        });

        return {
            response: response.data,
            params
        };
    } catch (err) {
        const errorMessage = {
            name: "fetchOrders error",
            message: err.response.data.message
        };
        throw errorMessage;
    }
})

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchOrders.pending]: (state, action) => {
            state.loading = true
            state.error = ''
        },
        [fetchOrders.fulfilled]: (state, action) => {
            state.loading = false
            state.params = action.payload.params
            state.data.total = action.payload.response.total
            state.data.data = action.payload.response.data
            state.data.simpleDashboard = action.payload.response.simple_dashboard
        },
        [fetchOrders.rejected]: (state, action) => {
            state.loading = false
            state.error = action.error.message
        }
    },
})

export default orderSlice.reducer

export const selectAllOrders = (state) => {
    return state.orders
}
