import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: false,
  data: [],
  error: ''
};

export const fetch = createAsyncThunk('simplePacking/fetch',
  async ({warehouseId, accessToken}) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/simple-packing', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      params: {
        warehouse_id: warehouseId
      }
    });

    return response.data;
  })

const simplePackingSlice = createSlice({
  name: 'simplePacking',
  initialState,
  reducers: {},
  extraReducers: {
    [fetch.pending]: (state, action) => {
      state.loading = true
      state.error = ''
      state.data = []
    },
    [fetch.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [fetch.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export default simplePackingSlice.reducer

export const selectAll = (state) => {
  return state.simplePackingList
}
