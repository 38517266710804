import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: false,
  data: [],
  sku: null,
  error: ''
};

export const fetchWarehouseStockBySku = createAsyncThunk('warehouseStockBySku/fetch', async ({sku, accessToken}) => {
  const response = await axios.get(process.env.REACT_APP_API_URL + '/orders/warehouse-stock-by-sku', {
    headers: {
      Authorization: "Bearer " + accessToken
    },
    params: {
      sku
    }
  });

  return {
    response: response.data
  };
})

const warehouseStockBySkuSlice = createSlice({
  name: 'warehouseStockBySku',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWarehouseStockBySku.pending]: (state, action) => {
      state.loading = true
      state.data = []
      state.error = ''
    },
    [fetchWarehouseStockBySku.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload.response.data
    },
    [fetchWarehouseStockBySku.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    }
  },
})

export default warehouseStockBySkuSlice.reducer

export const selectAllWarehouseStockBySku = (state) => {
  return state.warehouseStockBySku
}
