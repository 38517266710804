import axios from 'axios'

export const FETCH_PICKING_LIST_REQUEST = 'FETCH_PICKING_LIST_REQUEST';
export const FETCH_PICKING_LIST_SUCCESS = 'FETCH_PICKING_LIST_SUCCESS';
export const FETCH_PICKING_LIST_FAILURE = 'FETCH_PICKING_LIST_FAILURE';

export const fetchPickingListRequest = () => {
    return {
        type: FETCH_PICKING_LIST_REQUEST
    }
};

export const fetchPickingListSuccess = (items) => {
    return {
        type: FETCH_PICKING_LIST_SUCCESS,
        payload: items
    }
};

export const fetchPickingListFailure = (error) => {
    return {
        type: FETCH_PICKING_LIST_FAILURE,
        payload: error
    }
};

export const fetchPickingList = (accessToken) => {
    return (dispatch) => {
        dispatch(fetchPickingListRequest());
        axios.get(process.env.REACT_APP_API_URL + '/picking', {
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
            .then(response => {
                const items = response.data;
                dispatch(fetchPickingListSuccess(items));
            })
            .catch(error => {
                console.log(error);
                const errorMsg = error.message;
                dispatch(fetchPickingListFailure(errorMsg));
            })
    }
};

