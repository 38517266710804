import React, {Suspense, lazy, Fragment} from "react";
import Loader from "react-loaders";
import {Route} from "react-router-dom";

const Forbidden = lazy(() => import("../../Pages/Forbidden"));
const Dashboard = lazy(() => import("../../Pages/Dashboard"));
const Orders = lazy(() => import("../../Pages/Orders"));
const OrderShow = lazy(() => import("../../Pages/Orders/Show"));
const RevisionList = lazy(() => import("../../Pages/RevisionList"));
const SimplePicking = lazy(() => import("../../Pages/SimplePicking"));
const SimplePacking = lazy(() => import("../../Pages/SimplePacking"));
const Picking = lazy(() => import("../../Pages/Picking"));
const PickingInbox = lazy(() => import("../../Pages/Picking/Inbox"));
const PickingShow = lazy(() => import("../../Pages/Picking/Show"));

const AppMain = () => {

  const fallbackMessage = () => {
    return (<div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse-rise" active/>
        </div>
        <h6 className="mt-5">
          Cargando componentes
          <small>Aguarde un momento por favor.</small>
        </h6>
      </div>
    </div>)
  }

  return (
    <Fragment>
      {/* Forbidden */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/forbidden" component={Forbidden}/>
      </Suspense>

      {/* Dashboard */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/" component={Dashboard}/>
      </Suspense>

      {/* Order Show */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/order/:id" component={OrderShow}/>
      </Suspense>

      {/* Orders */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/orders" component={Orders}/>
      </Suspense>

      {/* REvision List */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/revision-list" component={RevisionList}/>
      </Suspense>

      {/* Simple Picking */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/simple-picking" component={SimplePicking}/>
      </Suspense>

      {/* Simple Packing */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/simple-packing" component={SimplePacking}/>
      </Suspense>

      {/* Picking */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/picking" component={Picking}/>
      </Suspense>

      {/* Picking Inbox */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/picking/inbox" component={PickingInbox}/>
      </Suspense>

      {/* Picking Show */}
      <Suspense fallback={fallbackMessage()}>
        <Route exact path="/picking/container/:id" component={PickingShow}/>
      </Suspense>
    </Fragment>
  )
};

export default AppMain;
