import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: false,
  data: [],
  error: ''
};

export const fetchInbox = createAsyncThunk('pickingInbox/fetchInbox',
  async ({warehouseId, accessToken}) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/picking/inbox', {
      headers: {
        Authorization: "Bearer " + accessToken
      },
      params: {
        warehouse_id: warehouseId
      }
    });

    return response.data;
  })

export const confirmPicking = createAsyncThunk('pickingInbox/confirmPicking',
  async ({containerId, items, accessToken}) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/picking', {
        container_id: containerId,
        items
      }, {
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });

      return response.data;
    } catch (err) {
      if (err.response.status === 422) {
        return err.response.data;
      }
    }
  })

const pickingInboxSlice = createSlice({
  name: 'pickingInbox',
  initialState,
  reducers: {
    itemChange(state, action) {
      const {orderDetailId, checked} = action.payload
      const existingItem = state.data.find((item) => item.orderDetailId === orderDetailId)
      if (existingItem) {
        existingItem.checked = checked;
      }
    }
  },
  extraReducers: {
    // fetch
    [fetchInbox.pending]: (state, action) => {
      state.loading = true
      state.error = '';
      state.data = []
    },
    [fetchInbox.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [fetchInbox.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    // Confirm
    [confirmPicking.pending]: (state, action) => {
      state.loading = true
      state.error = '';
      state.data = []
    },
    [confirmPicking.fulfilled]: (state, action) => {
      state.loading = false
    },
    [confirmPicking.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    }
  },
})

export const {itemChange} = pickingInboxSlice.actions

export default pickingInboxSlice.reducer

export const selectAllInbox = (state) => {
  return state.pickingListInbox
}
