import {createStore, applyMiddleware, combineReducers} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger"
import thunk from "redux-thunk";
import reducers from "../reducers";

export default function configureStore() {
    return createStore(
        combineReducers({
            ...reducers,
        }), composeWithDevTools(
            applyMiddleware(thunk, logger)
        )
    );
}
