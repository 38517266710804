import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: true,
  order: {},
  error: ''
};

export const fetchOrderById = createAsyncThunk('orders/fetchOrderById',
  async ({id, accessToken, take}) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/orders/' + id, {
        headers: {
          Authorization: "Bearer " + accessToken
        },
        params: {
          take
        }
      });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "fetchOrderById error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

export const updateOrder = createAsyncThunk('orders/updateOrder',
  async ({id, values, accessToken}) => {
    try {
      const response = await axios.patch(process.env.REACT_APP_API_URL + '/orders/' + id,
        values, {
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });

      return response.data;
    } catch (err) {
      const errorMessage = {
        name: "updateOrder error",
        message: err.response.data.message
      };
      throw errorMessage;
    }
  })

const orderShowSlice = createSlice({
  name: 'orderShow',
  initialState,
  reducers: {},
  extraReducers: {
    // Order Show
    [fetchOrderById.pending]: (state, action) => {
      state.loading = true
      state.error = ''
    },
    [fetchOrderById.fulfilled]: (state, action) => {
      state.loading = false
      state.order = action.payload
    },
    [fetchOrderById.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },

    // Order update
    [updateOrder.pending]: (state, action) => {
      state.error = ''
    },
    /*[updateOrder.fulfilled]: (state, action) => {
      state.loading = false
    },*/
    [updateOrder.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})

export const {orderUpdated} = orderShowSlice.actions

export default orderShowSlice.reducer

export const selectOrderById = (state, orderId) => {
  return state.orderShow
}
